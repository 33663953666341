import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Pattern } from '@fuse/utils/systemEnums';

@Component({
  selector: 'app-change-profile-dialog',
  templateUrl: './change-profile-dialog.component.html',
  styleUrls: ['./change-profile-dialog.component.scss']
})
export class ChangeProfileDialogComponent implements OnInit {
  Enum = Pattern
  constructor(
    public dialogRef: MatDialogRef<ChangeProfileDialogComponent>,
    private fb: FormBuilder
  ) {

   }
  
  changeProfileForm: FormGroup;
  ngOnInit() {
    this.changeProfileForm = this.fb.group({
      newName: [localStorage.getItem('userName'), Validators.required],
      email:[localStorage.getItem('userEmail'),Validators.required]
    })
  }

}
