// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    firebase: {
        apiKey: "AIzaSyDfKAy2Um6cc4x4kHg3c1qNVzDjhUeQGV4",
        authDomain: "uniquetags-399aa.firebaseapp.com",
        projectId: "uniquetags-399aa",
        storageBucket: "uniquetags-399aa.appspot.com",
        messagingSenderId: "470377298806",
        appId: "1:470377298806:web:49aa66b205a18e21b8d857",
        measurementId: "G-2EXB7RZ2R5"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
