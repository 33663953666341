import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  message: string = "Are you sure you want to delete this?";
  confirmButtonText = "Yes";
  cancelButtonText = "Cancel";
  DeleteData;
  isDeclineReasonIsRequired: boolean;
  errMsg: boolean;
  declineReason:any = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      console.log(data)
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
      if (data.module == "moderatorRequest") {
        this.isDeclineReasonIsRequired = true;
      }
    }



  }
  ngOnInit() {
  }

  onConfirmClick(reason?): void {
    console.log("onConfirmClick click")
    if (this.isDeclineReasonIsRequired) {
      console.log(this.declineReason);
      
      if (this.declineReason!='' && this.declineReason!=null && this.declineReason!= undefined){
        this.DeleteData = {
          delete: true,
          reason: this.declineReason
        };
        this.dialogRef.close(this.DeleteData);
      }else{
        this.errMsg = true;
      }
        
    } else {
      this.DeleteData = {
        delete: true,
      };
      this.dialogRef.close(this.DeleteData);
    }

  }
}
