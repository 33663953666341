import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
} from "@angular/router";
import { log } from "console";
import { Observable } from "rxjs";
// import { AuthService } from './main/pages/auth.service';

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  isBusinessLogin: string;
  activateRoute: any;

  constructor(
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | any {
  //   // console.log("can activate route ===> ",);
  //   // route._routerState.url
  //   const userToken = localStorage.getItem('userToken')
  //   this.isBusinessLogin = localStorage.getItem('isBusinessUser')

  //   if (!userToken) {
  //     this.router.navigate(['/auth/login'])
  //     return false
  //   } else {
  //       if(this.isBusinessLogin){

  //         if(state.url ! == '/dashboard'){
  //           return false
  //         }

  //       }else{

  //       }
  //     return true
  //   }
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | any {
    const userToken = localStorage.getItem("userToken");
    this.isBusinessLogin = localStorage.getItem("isBusinessUser");

    if (!userToken) {
      this.router.navigate(["/auth/login"]);
      return false;
    } else {
      if (this.isBusinessLogin) {
        
        const bussinessAuthList = ['/dashboard' , '/settings' , '/user-manage']

          // console.log("bussinessAuthList.includes(state.url) ==>",bussinessAuthList.includes(state.url));
          
        if(bussinessAuthList.includes(state.url)){
          return true;
        }else{
          this.router.navigate(["/dashboard"]);
          return false;
        }


        if (state.url !== "/dashboard") {
          this.router.navigate(["/dashboard"]);
          return false;
        }


      } else {

      }
      return true;
    }
  }
}
