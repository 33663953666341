import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent
{
    public message: string;
    public title: string;
    public confirmBtn: string;
    public okayBtn: string;
    /**
     * Constructor
     *
     * @param {MatDialogRef<FuseConfirmDialogComponent>} dialogRef
     */
    constructor(
        public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
       @Inject(MAT_DIALOG_DATA) private data: any,
    )
    {
        if(dialogRef){
            this.message =  data.message;
            this.title =  data.title;
            this.confirmBtn = data.confirmBtn;
            this.okayBtn = data.okayBtn;
        }
    }

}
