import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ConfirmationDialogComponent } from './Dialog/confirmation-dialog/confirmation-dialog.component';
import { CropperComponent } from './Dialog/cropper/cropper.component';
import { MatButtonModule } from '@angular/material/button';

import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
// import { AddEventTypeDialogComponent } from './Dialog/add-event-type-dialog/add-event-type-dialog.component';
// import { AddOrganiserDialogComponent } from './Dialog/add-organiser-dialog/add-organiser-dialog.component';
// import { AddTicketTypeDialogComponent } from './Dialog/add-ticket-type-dialog/add-ticket-type-dialog.component';
// import { AddGuestDialogComponent } from './Dialog/add-guest-dialog/add-guest-dialog.component';
// import { AddEventCategoryDialogComponent } from './Dialog/add-event-category-dialog/add-event-category-dialog.component';
// import { AssignEventDialogComponent } from './Dialog/assign-event-dialog/assign-event-dialog.component';
// import { UserDailogComponent } from './Dialog/user-dailog/user-dailog.component';
// import { ModeratorRequestDailogComponent } from './Dialog/moderator-request-dailog/moderator-request-dailog.component';
// import { GroupDailogComponent } from './Dialog/group-dailog/group-dailog.component';
// import { UsersListDailogComponent } from './Dialog/users-list-dailog/users-list-dailog.component';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import { NgxPaginationModule } from 'ngx-pagination';
// import { AlertsDailogComponent } from './Dialog/alerts-dailog/alerts-dailog.component';
// import { AddPlantDailogComponent } from './Dialog/add-plant-dailog/add-plant-dailog.component';
import {MatSelectModule} from '@angular/material/select';
// import { SingleUserDailogComponent } from './Dialog/single-user-dailog/single-user-dailog.component';
// import { AddReportDialogComponent } from './Dialog/add-report-dialog/add-report-dialog.component';
import {MatRadioModule} from '@angular/material/radio';

import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationReasonDialogComponent } from './Dialog/confirmation-reason-dialog/confirmation-reason-dialog.component';
import {MatTooltipModule} from '@angular/material/tooltip';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        ImageCropperModule,
        MatSliderModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        MatTableModule,
        MatCardModule,
        NgxPaginationModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatDialogModule,
        MatTooltipModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        ConfirmationReasonDialogComponent,
        // UserDailogComponent,
        // ModeratorRequestDailogComponent,
        // GroupDailogComponent,
        // UsersListDailogComponent,
        // SingleUserDailogComponent,
        // AlertsDailogComponent,
        // AddPlantDailogComponent,
        MatTableModule,
        MatDialogModule,
        MatCardModule,
        NgxPaginationModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatTooltipModule
    ],
    declarations: [
             ConfirmationDialogComponent,
             CropperComponent,
             ConfirmationReasonDialogComponent],
    entryComponents: [
        ConfirmationDialogComponent, 
        CropperComponent,
        ConfirmationReasonDialogComponent]
})
export class FuseSharedModule {
}
