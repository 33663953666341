import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drpSearch'
})
export class DrpSearchPipe implements PipeTransform {
  searchText: string;

  transform(value: any[], searchText:string) {
    // console.log("value ===>",value);
    // console.log("value for search ===>",searchText.trim());
    this.searchText = searchText.trim()
   
    if (searchText.trim()) {
        return value.filter(item => item.name.toLowerCase().startsWith(searchText.toLowerCase()))
      } else {
        return value
      }
  }

}
