import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./auth-guard.service";





const appRoutes: Routes = [
  {
      path: 'dashboard',
      loadChildren: './main/dashboard/project/project.module#ProjectDashboardModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'auth',
      loadChildren: './main/pages/pages.module#PagesModule',
  },   
  {
      path: 'user-manage',
      loadChildren: './main/user-manage/user-manage.module#UserManageModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'manage-pet',
      loadChildren: './main/manage-pet/manage-pet.module#ManagePetModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'manage-tags',
      loadChildren: './main/manage-tags/manage-tags.module#ManageTagsModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'manage-business',
      loadChildren: './main/manage-business/manage-business.module#ManageBusinessModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'user-pages',
      loadChildren: './main/user-pages/user-pages.module#UserPagesModule',
      canActivate: [AuthGuardService]
  },
  {
      path: 'premium-tags',
      loadChildren: './main/premium-tags/premium-tags.module#PremiumTagsModule',
      canActivate: [AuthGuardService]
  },
//   {
//       path: 'chat',
//       loadChildren: './main/chat/chat.module#ChatModule',
//       canActivate: [AuthGuardService]
//   },
  {
      path: 'settings',
      loadChildren: './main/settings/settings.module#SettingsModule',
      canActivate: [AuthGuardService]
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: 'forgot-password', redirectTo: 'auth/forgot-password', pathMatch: 'full' },
  {
      path: '**',
      redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})


export class AppRoutingModule { }
